import React from 'react'
import { Col, Row } from 'reactstrap'
import Papa from "papaparse";

function Success({data, cancel}) {

   

    const handleDownload = () => {
        const csv = Papa.unparse(data.contents);
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "report.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    return (
        <React.Fragment>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Success</h5>
        </div>
  
        <div className="modal-body">
          <Row>
            <Col className="d-flex justify-content-center">
              <i className="fas fa-check-double font-size-28 text-success"></i>{" "}
            </Col>
          </Row>
          <Row>
            <Col className="text-center text-success mt-3">
              <span>Bulk upload of address completed !!</span>
            </Col>
          </Row>
          <Row>
            <Col className="text-center mt-3">
              <span className='text-success'>Success: {data.success}</span>
            </Col>
          </Row>
          <Row>
            <Col className="text-center mt-3">
              <span className='text-danger'>Error: {data.error}</span>
            </Col>
          </Row>
          <Row>
            <Col className='text-center mt-3'>
                <span className='text-primary pointer' onClick={handleDownload}>Please click here to get the detailed report.</span>
            </Col>
          </Row>

        </div>
  
        <div className="modal-footer">
          
           <button
            type="button"
            className="btn btn-success"
            onClick={cancel}
          >
            OK
          </button>
        </div>
      </React.Fragment>
    )
}

export default Success
