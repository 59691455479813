import Cookies from "js-cookie";
import { Auth } from "aws-amplify";

const BASE_URL = process.env.REACT_APP_API_URL

const defaultHeaders = {
  'Content-Type': 'application/json',
};



const request = async (url, method, data = null, customHeaders = {}) => {

  const headers = { ...defaultHeaders, ...customHeaders }

  const options = {
    method,
    headers,
    credentials: "include",
    body: data ? JSON.stringify(data) : undefined,
  }

  try {
    const response = await fetch(`${BASE_URL}${url}`, options)


    // Handle authentication errors
    if ([401, 403].includes(response.status)) {
      logout();
      throw new Error("Unauthorized or forbidden. You have been logged out.");
    }

    // Handle other HTTP errors
    if (!response.ok) {
      let errorMessage = "Something went wrong!";
      try {
        const errorData = await response.json(); // Attempt to parse the error response
        errorMessage = errorData.message || errorMessage; // Use server-provided error message if available
      } catch (parseError) {
        console.warn("Failed to parse error response:", parseError);
      }

      // Throw an enhanced error
      throw new Error(`${response.status} ${response.statusText}: ${errorMessage}`);
    }

    // Return parsed JSON data
    return await response.json();
  } catch (error) {
    console.error("API Error:", error);

    // Return a detailed error message
    throw new Error(error.message || "Something went wrong while processing the request.");
  }
}

  const logout = async () => {
    try {
      // Sign out from AWS Cognito
      await Auth.signOut();

      // Optional: Clear additional cookies (if any)
      Cookies.remove('idToken'); // Replace 'userToken' with your cookie name
      Cookies.remove('email', { path: '/' });
      Cookies.remove('user', { path: '/' });

      Cookies.remove('accessToken');
      Cookies.remove('user', { path: '/' })
      Cookies.remove('role', { path: '/' })
      Cookies.remove('tenantId', { path: '/' })
      console.log('User signed out successfully.');
      // const res = await fetch(`${BASE_URL}/set-cookie/clear`,  {
      //   method: 'POST',
      //   body: null
      // })
      // console.log(res)
      window.location.href = '/';
      //navigate('/')
    } catch (error) {
      console.error('Error during sign out:', error);
    }

  }


  export const get = (url, customHeaders = {}) => request(url, 'GET', null, customHeaders);
  export const post = (url, data, customHeaders = {}) => request(url, 'POST', data, customHeaders);
  export const put = (url, data, customHeaders = {}) => request(url, 'PUT', data, customHeaders);
  export const del = (url, customHeaders = {}) => request(url, 'DELETE', null, customHeaders);