import React, { useContext } from "react"
import { Card, CardBody, Col } from "reactstrap"
import ApptReqCfgListContext from "./ApptReqContext"

function Header({onCreate}) {
  const { fetchAllApptSys } = useContext(ApptReqCfgListContext)

  const reload =() => {
    fetchAllApptSys()
  }

  return (
    <Card>
      <CardBody>
        <Col>
          <div className="float-start">
            <h5 className="text-primary">Appointment Request Configurations</h5>
          </div>
        </Col>
        <Col>
          <div className="float-end">
            <div className="d-flex gap-2">
              <div className="text-sm">
                <span
                  onClick={
                    reload
                  }
                  className="pointer text-primary"
                >
                  <i className="fas fa-sync-alt" />
                </span>
              </div>
            </div>
          </div>
        </Col>
      </CardBody>
    </Card>
  )
}

export default Header
