import QdContainer from "components/Common/QdContainer"
import React, { useEffect, useState } from "react"
import { Provider } from "./MasterLocationContext"
import Header from "./Header"
import SingleLocation from "./SingleLocation"
import { Card, CardBody, Col, Label, Row } from "reactstrap"
import BulkLocations from "./BulkLocations"

const MasterLocation = () => {

  const [inputType, setInputType] = useState('single')

  const handleRadioChange = value => {
    setInputType(value)
  }

  return (
    <Provider>
      <QdContainer>
        <Header />

        <Card className="p-2">
          <CardBody>
            <Row className="mb-4">
              <Label
                htmlFor="horizontal-firstname-Input"
                className="col-sm-3 col-form-label"
              >
                Input Type
              </Label>
              <Col sm={6}>
<Row>
                    <Col>
                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="mapType"
                          id="exampleRadios1"
                          value="single"
                          defaultChecked
                          onChange={e => {
                            handleRadioChange(e.target.value)
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios1"
                        >
                          Single
                        </label>
                        {"  "}
                      </div>
                    </Col>
                    <Col>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="mapType"
                          id="exampleRadios2"
                          value="bulk"
                          onChange={e => {
                            handleRadioChange(e.target.value)
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios2"
                        >
                          Bulk
                        </label>
                      </div>
                    </Col>
                  </Row>
              </Col>
            </Row>
            {inputType === 'single' && <SingleLocation />}
            {inputType === 'bulk' && <BulkLocations />}

          </CardBody>
        </Card>

      </QdContainer>
    </Provider>
  )
}

export default MasterLocation
