import QdContainer from 'components/Common/QdContainer'
import React, { useState } from 'react'
import { Provider } from './ApptReqCfgDetailContext'
import ApptReqCfgDetails from './ApptReqCfgDetails'
import NewLocConfig from './NewLocConfig'
import { useParams } from 'react-router-dom'

function ApptReqCfgDetailsPage() {

    const [newConfig, setNewConfig] = useState(false)
    const [isGlobal, setIsGlobal] = useState(false)
    const [globalConfig, setGlobalConfig] = useState(null)
    const { apptSysType } = useParams()

    
    const hideNewConfig = () => {
        setNewConfig(false)
    } 

    const showNewConfig = (isGlobal, globalConfig) => {
        setIsGlobal(isGlobal)
        setNewConfig(true)
        if (globalConfig ) {
            setGlobalConfig(globalConfig)
        }
    }

    return (<Provider>
        <QdContainer>
            {!newConfig && <ApptReqCfgDetails showNewConfig={showNewConfig}/>}
            {newConfig && <NewLocConfig hideNewConfig={hideNewConfig} apptSysType={apptSysType} isGlobal={isGlobal} globalConfig={globalConfig}/>}
        </QdContainer>
    </Provider>)

}

export default ApptReqCfgDetailsPage