import QdContainer from 'components/Common/QdContainer'
import React from 'react'
import { Provider } from './ApptReqContext'
import ApptSystemList from './ApptSystemList'

function ApptReqCfg() {

    return (
        <Provider>
            <QdContainer>
                <ApptSystemList />
            </QdContainer>
        </Provider>
    )
}

export default ApptReqCfg