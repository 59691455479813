import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Form, Label, Modal, Row } from 'reactstrap'
import ApptReqCfgDetailContext from './ApptReqCfgDetailContext'
import { useFormik } from 'formik'
import * as Yup from "yup"
import Select from "react-select";
import TemplateVars from './TemplateVars'
import Success from 'pages/NotificationModal/Success'
import LoadingOverlay from 'react-loading-overlay'
import Error from 'pages/NotificationModal/Error'

function NewLocConfig({ hideNewConfig, apptSysType, isGlobal, globalConfig }) {

    const { fetchAllLocForApptSys, locationsByApptSys, createConfig, updateConfig, success, setSuccess, loading, error, setError } = useContext(ApptReqCfgDetailContext)
    const [location, setLocation] = useState(null)
    const [vars, setVars] = useState(globalConfig && globalConfig['#LIT#appt_req_extvars_cfg']?.vars || [])


    useEffect(() => {
        console.log('isGlobal' + isGlobal)
        loadData()

    }, [])

    const customStyles = {
        control: (base: any, state: any) => ({
            ...base,
            borderColor: validation.touched.location && validation.errors.location ? "red" : base.borderColor,
            "&:hover": {
                borderColor: validation.touched.location && validation.errors.location ? "red" : base.borderColor,
            },
        }),
    };

    const loadData = async () => {
        await fetchAllLocForApptSys(apptSysType)
        if (isGlobal) {
            setLocation({
                'm_loc_id': '*',
                'm_loc_nm': 'All'
            })
        }
    }

    const handleLocationChange = (selectedOption) => {
        validation.setFieldValue("location", selectedOption)
        setLocation(selectedOption)
    }


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            location: location
        },
        validationSchema: Yup.object({
            location: Yup.object()
                .shape({
                    m_loc_id: Yup.string().required()
                })
                .nullable()
                .required(
                    "Please select location"
                ),


        }),

        onSubmit: values => {
            save(values)
        },
    })

    const save = async (values) => {
        debugger
        let config = {}
        if (isGlobal) {
            if (globalConfig) {

                if (globalConfig['#LIT#appt_req_extvars_cfg']) {
                    globalConfig['#LIT#appt_req_extvars_cfg'].vars = vars
                    config = {...globalConfig}
                } else {
                    config = {
                        ...globalConfig,
                        '#LIT#appt_req_extvars_cfg': {
                            vars: vars
                        }
                    }
                }
                
                const obj = {
                    m_loc_id: location.m_loc_id,
                    config: config,
                    appt_sys_type: apptSysType,
                    isGlobal: isGlobal
                }
                console.log(obj)
                try {
                    const res = await updateConfig(obj)
                    console.log(res)
                } catch (error) {
                    console.error(error)
                }
            } else {
                await createNewConfig()
            }
            
        } else {
            await createNewConfig()
        }



        async function createNewConfig() {
            config = {
                '#LIT#appt_req_extvars_cfg': {
                    'vars': vars
                }
            }
            const obj = {
                m_loc_id: location.m_loc_id,
                config: config,
                appt_sys_type: apptSysType,
                isGlobal: isGlobal
            }
            console.log(obj)
            try {
                const res = await createConfig(obj)
                console.log(res)
            } catch (error) {
                console.error(error)
            }
        }
    }

    const togSuccess = async () => {
        setSuccess(!success)
        hideNewConfig()
    }

    const closeSuccess = () => {
        setSuccess(false)
        hideNewConfig()
    }

    const togError = async () => {
        setError(!error)

    }

    const closeError = () => {
        setError(false)
    }




    return (
        <>
            <Modal isOpen={success} toggle={togSuccess}>
                <Success
                    message="Configuration saved successfully !"
                    close={closeSuccess}
                />
            </Modal>
            <Modal isOpen={error} toggle={togError}>
                <Error
                    message="Failed to save the configuration"
                    closeModal={closeError}
                />
            </Modal>
            <Card>
                <CardBody>
                    <Col>
                        <div className="float-start">
                            <h5 className="text-primary">New Location Configuration</h5>
                        </div>
                    </Col>

                </CardBody>
            </Card>
            <Card>
                <LoadingOverlay active={loading} spinner>
                    <CardBody>
                        {!loading && <>
                            <Form onSubmit={
                                validation.handleSubmit}
                                className="p-2">
                                <Row className="mb-4">
                                    <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-2 col-form-label"
                                    >
                                        Locations
                                    </Label>
                                    <Col sm={6}>
                                        <Select
                                            name="location"
                                            options={locationsByApptSys}
                                            value={validation.values.location || location}
                                            isMulti={false}
                                            onChange={handleLocationChange}
                                            onBlur={() => validation.setFieldTouched("location", true)}
                                            className="select2-selection"
                                            styles={customStyles}
                                            isClearable
                                            getOptionLabel={(e) => `${e.m_loc_nm}`} // Display both fields
                                            getOptionValue={(e) => e.m_loc_id}
                                            isDisabled={isGlobal}
                                        />

                                        {validation.touched.location && validation.errors.location ? (
                                            <div style={{ color: "red" }}>{validation.errors.location}</div>
                                        ) : null}
                                    </Col>
                                </Row>
                                <Row>
                                    <TemplateVars vars={vars} setVars={setVars} isSave={false} />
                                </Row>

                                <div className='modal-footer'>
                                    <Button color='primary' type='submit' className='btn-sm btn m-3'>Save</Button>
                                    <Button onClick={() => { hideNewConfig() }} className='btn-sm btn'>Cancel</Button>
                                </div>
                            </Form> </>}
                        {loading && <div>Loading...</div>}
                    </CardBody>
                </LoadingOverlay>
            </Card>

        </>
    )
}

export default NewLocConfig