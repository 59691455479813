import React, { useContext, useEffect, useMemo, useState } from 'react'
import Header from './Header'
import ApptReqCfgListContext from './ApptReqContext'
import { Card, CardBody } from 'reactstrap'
import ApptReqConfTableContainer from 'components/Common/ApptReqConfTableContainer'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'

function ApptSystemList() {
    const { loading, fetchAllApptSys, apptSysTypes } = useContext(ApptReqCfgListContext)
    const navigate = useNavigate()

    useEffect(() => {
        fetchData()
        

    }, [])

    const fetchData = async () => {
        await fetchAllApptSys()
    }

    const handleCredClick = (apptSysKey) => {
        navigate(apptSysKey)
    }

    const getColumns = () => {
        return useMemo(
            () => [
                {
                    accessor: "key",
                    Header: "Appointment System"
                },
                
                {
                    accessor: "",
                    Header: "Action",
                    Cell: ({ row }) => (
                        <Box>
                          {row.original.key && (
                            <div>
                              <span className='text-primary' onClick={() => {handleCredClick(row.original.key)}}>Click here to get details</span>
                            </div>
                          )}
                        </Box>
                      ),
                },
            ],
            []
        )
    }

    const columns = getColumns()
    const refresh = () => {
        fetchAllApptSys()
    }

    return (
        <>
            <Header />
            {loading && <div>Loading...</div>}
            {!loading && apptSysTypes && <Card>
                <CardBody>
                    <ApptReqConfTableContainer
                        columns={columns}
                        data={apptSysTypes}
                        isGlobalFilter={true}
                        customPageSize={50}
                        loading={loading}
                        refreshTable={refresh}
                        className="custom-header-css"
                    />
                </CardBody>
            </Card>}
        </>
    )
}

export default ApptSystemList
