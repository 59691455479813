import React, { useContext, useEffect, useState } from 'react'
import TemplateVars from './TemplateVars'
import { Card, CardBody, Modal, Row } from 'reactstrap'
import TemplateValidations from './TemplateValidations'
import LoadingOverlay from 'react-loading-overlay'
import Success from 'pages/NotificationModal/Success'
import ApptReqCfgDetailContext from './ApptReqCfgDetailContext'

function ConfigData({ data, isGlobal, apptSysType, locName }) {
    const [vars, setVars] = useState(data.config && data.config['#LIT#appt_req_extvars_cfg'] ? data.config['#LIT#appt_req_extvars_cfg'].vars : [] || [])
    const [validations, setValidations] = useState(data.config && data.config['#LIT#appt_req_extvars_cfg'] ? data.config['#LIT#appt_req_extvars_cfg'].validations : [] || [])

    const { loading, updateConfig, fetchAllLocConfig, success, setSuccess } = useContext(ApptReqCfgDetailContext)
    console.log(data)
    console.log('vars --> ' + vars)

    const save = async (vars) => {
        try {
            if (data.config['#LIT#appt_req_extvars_cfg']) {
                data.config['#LIT#appt_req_extvars_cfg'].vars = vars
            } else {
                data.config = {
                    ...data.config,
                    '#LIT#appt_req_extvars_cfg': {
                        vars: vars
                    }
                }
            }
            var obj = {
                'isGlobal': isGlobal,
                'config': data.config,
                'appt_sys_type': apptSysType,
                'm_loc_id': data.m_loc_id
            }
            await updateConfig(obj)

        } catch (error) {
            console.error(error)
        }
    }
    const togSuccess = async () => {
        setSuccess(!success)
        await fetchAllLocConfig(apptSysType)
    }

    const closeSuccess = () => {
        setSuccess(false)
        fetchAllLocConfig(apptSysType)
    }

    const saveValidations = async(vals) => {
        try {
            if (data.config['#LIT#appt_req_extvars_cfg']) {
                data.config['#LIT#appt_req_extvars_cfg'].validations = vals
            } else {
                data.config = {
                    ...data.config,
                    '#LIT#appt_req_extvars_cfg': {
                        validations: vals
                    }
                }
            }
            var obj = {
                'isGlobal': isGlobal,
                'config': data.config,
                'appt_sys_type': apptSysType,
                'm_loc_id': data.m_loc_id
            }
            await updateConfig(obj)

        } catch (error) {
            console.error(error)
        }
    }



    return (
        <>
            <Modal isOpen={success} toggle={togSuccess}>
                <Success
                    message="Configuration saved successfully !"
                    close={closeSuccess}
                />
            </Modal>
            <Card className="border mt-3 mb-3" outline color="primary">
                <LoadingOverlay active={loading} spinner>
                    <CardBody>
                        {locName && <Row>
                            <h6 className='text-success'>{locName}</h6>
                            <hr/>
                        </Row>}
                        <Row className='mb-3'>
                            <TemplateVars vars={vars} isGlobal={isGlobal} save={save} isSave={true}/>
                        </Row>
                        <Row>
                            <TemplateValidations validations={validations} save = {saveValidations} isSave={true} isGlobal={isGlobal}/>
                        </Row>
                    </CardBody>
                </LoadingOverlay>
            </Card>
        </>

    )
}

export default ConfigData