import React, { createContext, useState } from 'react'
import { get, post } from 'utils/apiClient'

const MasterLocationContext = createContext()

function Provider({ children }) {
    const api_url = process.env.REACT_APP_API_URL

    const [apptSysTypes, setApptSysTypes] = useState([])
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [msg, setMsg] = useState()
    const [bulkResponse, setBulkResponse] = useState()

    const fetchAllApptSys = async (search_term) => {
        setLoading(true)
        let response = null
        try {
            const url = search_term ? 'appt-sys/all?search_term=' + search_term : 'appt-sys/all'
            response = await get(url)
            console.log(response)
            setApptSysTypes(response)
            setLoading(false)
        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }

    const saveBulk = async (obj) => {
        setLoading(true)
        let response = null
        try {
            const url = 'location-management/master-locations/bulk'
            response = await post(url, obj)
            setBulkResponse(response)
            setLoading(false)
            setSuccess(true)
        } catch (error) {
            console.error(error)
            setLoading(false)
        }

    }

    const valueToShare = {
        loading,
        success,
        setSuccess,
        apptSysTypes,
        fetchAllApptSys,
        error,
        setError,
        msg,
        saveBulk,
        bulkResponse
    }

    return (
        <MasterLocationContext.Provider value={valueToShare}>{children}</MasterLocationContext.Provider>
    )

}


export { Provider }
export default MasterLocationContext