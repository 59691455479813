import React, { createContext, useState } from "react"
import { get, post, put } from "utils/apiClient"


const ApptReqCfgListContext = createContext()


function Provider({ children }) {
    const api_url = process.env.REACT_APP_API_URL

    const [apptSysTypes, setApptSysTypes] = useState([])
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [msg, setMsg] = useState()
    const [locationsByApptSys, setLocationsByApptSys] = useState([])
    const [locConfig, setLocConfig] = useState()

    const fetchAllApptSys = async (search_term) => {
        setLoading(true)
        let response = null
        try {
          const url = search_term ? 'appt-sys/all?search_term=' + search_term : 'appt-sys/all'
          response = await get(url)
          console.log(response)
          setApptSysTypes(response)
          setLoading(false)
        } catch (error) {
          console.error(error)
          setLoading(false)
        }
    
    }

    const fetchAllLocForApptSys = async (apptSysType) => {
        setLoading(true)
        try {
          const response = await get('/locations?appt_sys_type='+apptSysType)
          
          let result = response?.sort((a, b) =>
            a.tLocationName.localeCompare(b.appt_sys)
          )
          setLoading(false)
          setLocationsByApptSys(result)
    
    
        } catch (error) {
          console.error(error)
          setLoading(false)
        }
    }

    const fetchAllLocConfig = async (appt_sys_type) => {
        try {
            setLoading(true)
            const response = await get('appt-sys/loc-config?appt_sys_type=' + appt_sys_type)
            setLocConfig(response)
            setLoading(false)
        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }

    const valueToShare = {
        loading,
        success,
        setSuccess,
        apptSysTypes,
        fetchAllApptSys,
        fetchAllLocForApptSys,
        fetchAllLocConfig,
        locationsByApptSys,
        locConfig,
        error,
        msg,
      }
    
      return (
        <ApptReqCfgListContext.Provider value={valueToShare}>{children}</ApptReqCfgListContext.Provider>
      )
}


export { Provider }
export default ApptReqCfgListContext

