import React, { useContext, useEffect, useState } from "react"
import { Button, Col, Form, Input, Label, Modal, Row } from "reactstrap"
import MasterLocationContext from "./MasterLocationContext"
import Papa from "papaparse";
import Select from "react-select"
import { useFormik } from "formik"
import * as Yup from "yup"
import { get } from "utils/apiClient"
import MasterLocationUploadTableContainer from "components/Common/MasterLocationUploadTableContainer";
import { useMemo } from "react";
import LoadingOverlay from "react-loading-overlay";
import Success from "./Success";
import Error from "pages/NotificationModal/Error";

function BulkLocations() {
    const { fetchAllApptSys, loading, apptSysTypes, saveBulk, bulkResponse, success, error, setSuccess,  setError  } = useContext(MasterLocationContext)
    const [data, setData] = useState([]);
    const [selectedApptSys, setSelectedApptSys] = useState('')
    const [apptSysTypesForm, setApptSysTypesForm] = useState([])
    const [allApptSys, setAllApptSys] = useState([])

    useEffect(() => {
        loadData()

    }, [])

    const getColumns = () => {
        return useMemo(
            () => [
                {
                    accessor: "Address1",
                    Header: "Address",
                },
                {
                    accessor: "Name",
                    Header: "Name",
                },
                {
                    accessor: "City",
                    Header: "City",
                },
                {
                    accessor: "State",
                    Header: "State",
                },
                {
                    accessor: "Zip",
                    Header: "Zip",
                },
            ],
            []
        )
    }

    const loadData = async () => {
        const response = await get("appt-sys/all")
        const appt_sys = response ? response.map((item) => ({
            value: item.key,
            label: item.key,
        })) : []
        setAllApptSys(appt_sys)
    }

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        Papa.parse(file, {
            complete: (result) => {
                setData(result.data);
                console.log(data)
            },
            header: true,
            skipEmptyLines: true,
        });

    };

    const column = getColumns()


    const handleApptSyschange = (selectedOption) => {
        validation.setFieldValue("appt_system_type", selectedOption)
        setSelectedApptSys(selectedOption)

    }

    const customStyles = {
        control: (base: any, state: any) => ({
            ...base,
            borderColor: validation.touched.appt_system_type && validation.errors.appt_system_type ? "red" : base.borderColor,
            "&:hover": {
                borderColor: validation.touched.appt_system_type && validation.errors.appt_system_type ? "red" : base.borderColor,
            },
        }),
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            appt_system_type: selectedApptSys,

        },
        validationSchema: Yup.object({
            appt_system_type: Yup.object()
                .shape({
                    value: Yup.string().required(),
                    label: Yup.string().required()
                })
                .nullable()
                .required(
                    "Please enter valid appointment system type"
                ),
        }),


    })

    const cancel = () => {
        setData([])
        setSelectedApptSys(null)
        setError(false)
        setSuccess(false)

    }

    const save = () => {
        const addresses = data.map((item) => {
            return {
                'locationName': item.Name,
                'addr1': item.Address1,
                'city': item.City,
                'state': item.State,
                'zip': item.Zip,
            }
        })
        let obj = {
            'appt_sys_type': selectedApptSys.value,
            'addresses': addresses
        }
        saveBulk(obj)
    }

    const togSuccess = () => {
        setSuccess(false)
        cancel()
    }
    const togError = () => {
        setError(false)
    }




    return (
        <>
         <Modal
            isOpen={success}
            toggle={() => {
                togSuccess()
            }}
            centered
            size="md"
          >
            <Success
              data = {bulkResponse} cancel = {cancel}
            />
          </Modal>
          <Modal
            isOpen={error}
            toggle={() => {
              togError()
            }}
            centered
            size="sm"
          >
            <Error
              message = "Something went wrong"
              closeModal = {togError}
            />
          </Modal>
            <LoadingOverlay active={loading} spinner>
                {allApptSys && <Form onSubmit={
                    validation.handleSubmit}
                    className="p-2"> <Row className="mb-4">
                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                            Appointment System Type
                        </Label>
                        <Col sm={6}>
                            <Select
                                name="appt_system_type"
                                options={allApptSys}
                                value={validation.values.appt_system_type || selectedApptSys}
                                isMulti={false}
                                onChange={handleApptSyschange}
                                onBlur={() => validation.setFieldTouched("appt_system_type", true)}
                                className="select2-selection"
                                styles={customStyles}
                                isClearable
                            />

                            {validation.touched.appt_system_type && validation.errors.appt_system_type ? (
                                <div style={{ color: "red" }}>{validation.errors.appt_system_type}</div>
                            ) : null}
                        </Col>
                    </Row> </Form>}

                <Row className="mb-4">
                    <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                    >
                        Input File
                    </Label>
                    <Col sm={6}>
                        <Input className="form-control" type="file" id="formFile" onChange={handleFileUpload} />
                    </Col>
                </Row>
                {data && data.length > 0 && <Row>
                    <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                    >
                        Input Data
                    </Label>
                    <Col sm={9}>
                        {data.length > 0 && (
                            <MasterLocationUploadTableContainer columns={column} data={data} customPageSize={50} />
                        )}
                    </Col>
                </Row>}
                <Row className="mb-4">
                    <div className="modal-footer mt-4">
                        <Button color="primary me-2 btn btn-sm" onClick={save}>Save</Button>
                        <Button color="secondary me-2 btn btn-sm" onClick={cancel}>Cancel</Button>
                    </div>
                </Row>
            </LoadingOverlay>
        </>
    )
}

export default BulkLocations