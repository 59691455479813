import QdContainer from 'components/Common/QdContainer'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'
import ConfigData from './ConfigData'
import TenantLocationConfigTableContainer from 'components/Common/TenantLocationConfigTableContainer'
import ApptReqCfgDetailContext, { Provider } from './ApptReqCfgDetailContext'

function ApptReqCfgDetails({ showNewConfig }) {
  const { apptSysType } = useParams()

  const { fetchAllLocConfig, loading, locConfig } = useContext(ApptReqCfgDetailContext)
  const navigate = useNavigate()

  const getColumns = () => {
    return useMemo(
      () => [
        {
          accessor: "m_loc_nm",
          Header: "Location Name",
        },
        {
          accessor: "m_loc_id",
          Header: "Location Id",
        },

      ],
      []
    )
  }

  useEffect(() => {
    console.log(locConfig)
    loadData()
  }, [])

  const loadData = async () => {
    await fetchAllLocConfig(apptSysType)
    console.log(locConfig)
  }

  const columns = getColumns()

  const shouldShowGlobalConfig = () => {
    return locConfig.all_location && locConfig.all_location.config && locConfig.all_location.config['#LIT#appt_req_extvars_cfg'] && (locConfig.all_location.config['#LIT#appt_req_extvars_cfg']['vars'] || locConfig.all_location.config['#LIT#appt_req_extvars_cfg']['validations'])

  }

  const renderRowSubComponent = React.useCallback(({ row }) => (
    <ConfigData
      data={row.original}
      locName={row.original.m_loc_nm}
      apptSysType={apptSysType}
    />
  ))

  const goBack = () => {
    navigate('/appt-req-cfg')
  }



  return (
    <>
      <Card>
        <CardBody>
          <Col>
            <div className="float-start">
              <h5 className="text-primary">{apptSysType}</h5>
            </div>
          </Col>
          <Col>
            <div className="float-end">
              <div className="d-flex gap-2">
                <div className="text-sm">
                  <Button color='primary' className='btn-sm' onClick={goBack}>Back</Button>
                </div>
              </div>
            </div>
          </Col>
        </CardBody>
      </Card>
      {loading && <div>Loading...</div>}
      {locConfig && <>
        <Card className="border mt-3 mb-3" outline color="primary">
          <CardBody>
            <h5>All locations</h5>
            {shouldShowGlobalConfig() && <Row>
              <ConfigData data={locConfig.all_location} isGlobal={true} apptSysType={apptSysType} />
            </Row>}
            {!shouldShowGlobalConfig() && <div className="p-5">
              <div className="mb-2 d-flex justify-content-center">
                No global configuration found, create new
              </div>
              <div className="mt-3 d-flex justify-content-center">
                <button className="btn btn-primary" onClick={() => { showNewConfig(true, locConfig.all_location?.config)}}>Create</button>
              </div>
            </div>}
          </CardBody>
        </Card>
        <Card className="border mt-3 mb-3" outline color="primary">
          <CardBody>
            <Row>
              <Col>
                <h5>Locations</h5>
              </Col>
              <Col >
                <div className='float-end'><Button color="primary" className='btn btn-sm' onClick={() => { showNewConfig(false, locConfig.all_location?.config) }}>Add New Configuration</Button></div>
              </Col>
            </Row>
            <br />
            <TenantLocationConfigTableContainer columns={columns}
              data={locConfig.location_configs}
              customPageSize={50}
              loading={loading}
              renderRowSubComponent={renderRowSubComponent}
              className="custom-header-css" />
          </CardBody>
        </Card></>
      }
    </>
  )
}

export default ApptReqCfgDetails


