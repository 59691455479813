import React, { useEffect, useReducer, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import LoadingOverlay from "react-loading-overlay"
import Select from "react-select";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Form,
    Input,
    Label,
    Modal,
    Row,
    UncontrolledAlert,
} from "reactstrap"
import Success from "pages/NotificationModal/Success"
import QdContainer from "components/Common/QdContainer";
import { get, post } from "utils/apiClient";

const DynamicChildForm = ({ formFields, setFieldValue, parentValues, validation }) => {
    return (
        <div>
            <h5>Appointment System specific fields</h5>
            {formFields.map((field) => (
                <Row key={field.name} className="mb-4">
                    <label htmlFor={field.name} className="col-sm-3 col-form-label">{field.display_name} *</label>
                    <Col sm={6}>
                        {field.type == 'string' && <Input
                            type='text'
                            className="form-control"
                            id={field.name}
                            name={field.name}
                            value={parentValues.additional_data ? parentValues.additional_data[field.name] : ''}
                            onChange={(e) => setFieldValue(`additional_data.${field.name}`, e.target.value)} // setFieldValue for dynamic fields
                            placeholder={`Enter ${field.name}`}
                            required

                        />}
                        {field.type == 'integer' && <Input
                            type='number'
                            className="form-control"
                            id={field.name}
                            name={field.name}
                            value={parentValues.additional_data ? parentValues.additional_data[field.name] : ''}
                            onChange={(e) => setFieldValue(`additional_data.${field.name}`, e.target.value)} // setFieldValue for dynamic fields
                            placeholder={`Enter ${field.name}`}
                            required

                        />}
                        {field.type === 'boolean' && (
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id={field.name}
                                name={field.name}
                                value={parentValues.additional_data ? parentValues.additional_data[field.name] : false}
                                onChange={() => setFieldValue(`additional_data.${field.name}`, parentValues.additional_data ? !parentValues.additional_data[field.name] : false)} // setFieldValue for dynamic fields
                                placeholder={`Enter ${field.name}`}
                                checked={parentValues.additional_data ? parentValues.additional_data[field.name] : false}

                            />
                        )}

                    </Col>
                </Row>

            ))}
        </div>
    );
};

const intialState = {
    loading: false,
    showSuccess: false,
  }
  
  function reducer(state, action) {
    switch (action.type) {
      case "togLoading":
        return {
          ...state,
          loading: !state.loading,
        }
      case "togSuccess": {
        return {
          ...state,
          showSuccess: !state.showSuccess,
        }
      }
      default:
        return state
    }
  }

function SingleLocation() {

    const [state, dispatch] = useReducer(reducer, intialState)
    const [loading, setLoading] = useState(false)
    const api_url = process.env.REACT_APP_API_URL
    const [errorMessage, setErrorMessage] = useState()
    const [selectedApptSys, setSelectedApptSys] = useState(null)
    const [allApptSys, setAllApptSys] = useState([])
    const [apptSysFields, setApptSysFields] = useState(null)
    const [apptSysWithFields, setApptSysWithFields] = useState(null)


    useEffect(() => {
        loadAllApptSys()
    
      }, [])
    
      useEffect(() => {
        if (apptSysFields) {
          validation.setValues({
            ...validation.values,
            additional_data: apptSysFields.reduce((acc, field) => {
              acc[field.display_name] = ''
              return acc
            }, {}),
          })
        }
      }, [apptSysFields])
    
      const loadAllApptSys = async () => {
        try {
          dispatch({ type: "togLoading" })
          const response = await get("appt-sys/all")
          setApptSysWithFields(response)
          dispatch({ type: "togLoading" })
          const appt_sys = response ? response.map((item) => ({
            value: item.key,
            label: item.key,
          })) : []
          setAllApptSys(appt_sys)
    
    
        } catch (error) {
          console.error(error)
          dispatch({ type: "togLoading" })
          return [];
        }
      }
    
      const save = async values => {
        dispatch({ type: "togLoading" })
        setErrorMessage(null)
        console.log(values)
    
        let addData = values.additional_data
        
        apptSysFields?.forEach((field) => {
          const {name, type} = field
          switch (type) {
            case "integer":
              addData[name] = +addData[name]
              break
           
            default:
              console.log("pass")
          }
        })
    
        try {
          const data = {
            locationName: values.m_loc_name,
            addr1: values.addr1,
            city: values.city,
            state: values.state,
            zip: values.zip,
            appt_sys_type: values.appt_system_type.value,
            additional_data: addData
          }
          const response = await post('location-management/master-locations', data)
          setErrorMessage(null)
          dispatch({ type: "togLoading" })
          dispatch({ type: "togSuccess" })
          setSelectedApptSys(null)
        } catch (error) {
          console.error("Error:" + error)
          setErrorMessage(error.message)
          dispatch({ type: "togLoading" })
        }
      }
    
      const closeSuccess = () => {
        dispatch({ type: "togSuccess" })
        validation.resetForm()
        setApptSysFields(null)
      }
    
      const resetForm = () => {
        validation.resetForm()
        setApptSysFields(null)
        setErrorMessage(null)
        setSelectedApptSys(null)
      }
    
    
      const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
          m_loc_name: "",
          addr1: "",
          city: "",
          state: "",
          zip: "",
          appt_system_type: selectedApptSys,
          additional_data: apptSysFields?.reduce((acc, field) => {
            if (field.type == 'boolean') {
              acc[field.name] = false
            } else {
              acc[field.name] = ''
            }
            return acc;
          }, {})
    
        },
        validationSchema: Yup.object({
          m_loc_name: Yup.string().required("Please Enter location name"),
          addr1: Yup.string().required("Please Enter Address line "),
          appt_system_type: Yup.object()
            .shape({
              value: Yup.string().required(),
              label: Yup.string().required()
            })
            .nullable()
            .required(
              "Please enter valid appointment system type"
            ),
    
    
        }),
    
        onSubmit: values => {
          save(values)
        },
      })
    
      const customStyles = {
        control: (base: any, state: any) => ({
          ...base,
          borderColor: validation.touched.appt_system_type && validation.errors.appt_system_type ? "red" : base.borderColor,
          "&:hover": {
            borderColor: validation.touched.appt_system_type && validation.errors.appt_system_type ? "red" : base.borderColor,
          },
        }),
      };
    
      const handleApptSyschange = (selectedOption) => {
        validation.setFieldValue("appt_system_type", selectedOption)
        setSelectedApptSys(selectedOption)
    
        if (selectedOption) {
          dispatch({ type: "togLoading" })
          let localApptSysFields = apptSysWithFields.filter((item) => {
            return item.key == selectedOption.value
          })
          setApptSysFields(localApptSysFields[0].fields)
          console.log(localApptSysFields[0].fields)
    
          dispatch({ type: "togLoading" })
    
        } else {
          setApptSysFields(null)
        }
    
      }
    
      return (
        <React.Fragment>
          <Modal
            isOpen={state.showSuccess}
            toggle={() => {
              dispatch({ type: "togSuccess" })
            }}
            centered
            size="sm"
          >
            <Success
              message="Master location created successfully !!"
              close={closeSuccess}
            />
          </Modal>
            {/* <Card>
              <CardBody> */}
                <LoadingOverlay active={state.loading} spinner>
                 
                  <Row className="mt-2">
                    {errorMessage && (
                      <div className="mb-3">
                        <UncontrolledAlert color="danger" role="alert">
                          {errorMessage}
                        </UncontrolledAlert>
                      </div>
                    )}
                  </Row>
    
                  {!loading && allApptSys && <Form
                    onSubmit={
                      validation.handleSubmit}
                    className="p-2"
                  >
                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Appointment System Type
                      </Label>
                      <Col sm={6}>
                        <Select
                          name="appt_system_type"
                          options={allApptSys}
                          value={validation.values.appt_system_type || selectedApptSys}
                          isMulti={false}
                          //onChange={(selectedOption) => handleApptSyschange(selectedOption)}
                          onChange={handleApptSyschange}
                          onBlur={() => validation.setFieldTouched("appt_system_type", true)}
                          className="select2-selection"
                          styles={customStyles}
                          isClearable
                        />
    
                        {validation.touched.appt_system_type && validation.errors.appt_system_type ? (
                          <div style={{ color: "red" }}>{validation.errors.appt_system_type}</div>
                        ) : null}
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Location Name
                      </Label>
                      <Col sm={6}>
                        <Input
                          type="text"
                          className="form-control"
                          name="m_loc_name"
                          id="horizontal-firstname-Input"
                          placeholder="Enter location name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.m_loc_name || ""}
                          invalid={
                            validation.touched.m_loc_name &&
                              validation.errors.m_loc_name
                              ? true
                              : false
                          }
                        />
                      </Col>
                    </Row>
    
                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Address Line 1
                      </Label>
                      <Col sm={6}>
                        <Input
                          type="text"
                          className="form-control"
                          name="addr1"
                          id="horizontal-firstname-Input"
                          placeholder="Enter address line "
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.addr1 || ""}
                          invalid={
                            validation.touched.addr1 && validation.errors.addr1
                              ? true
                              : false
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        City
                      </Label>
                      <Col sm={6}>
                        <Input
                          type="text"
                          className="form-control"
                          name="city"
                          id="horizontal-firstname-Input"
                          placeholder="Enter city "
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.city || ""}
                          invalid={
                            validation.touched.city && validation.errors.city
                              ? true
                              : false
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        State
                      </Label>
                      <Col sm={6}>
                        <Input
                          type="text"
                          className="form-control"
                          name="state"
                          id="horizontal-firstname-Input"
                          placeholder="Enter state "
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.state || ""}
                          invalid={
                            validation.touched.state && validation.errors.state
                              ? true
                              : false
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Zip
                      </Label>
                      <Col sm={6}>
                        <Input
                          type="text"
                          className="form-control"
                          name="zip"
                          id="horizontal-firstname-Input"
                          placeholder="Enter postal zip "
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.zip || ""}
                          invalid={
                            validation.touched.zip && validation.errors.zip
                              ? true
                              : false
                          }
                        />
                      </Col>
                    </Row>
    
                    {apptSysFields && apptSysFields.length > 0 && (<DynamicChildForm
                      formFields={apptSysFields}
                      setFieldValue={validation.setFieldValue}
                      parentValues={validation.values}
                      validation={validation}
                    />)}
                    <Row className="mb-4 me-3">
                      <div className="modal-footer">
                        <Button
                          type="submit"
                          color="primary"
                          className="btn btn-primary me-2"
                        >
                          Save
                        </Button>
                        <Button
                          type="button"
                          color="secondary"
                          className="btn btn-secondary me-2"
                          onClick={resetForm}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Row>
                  </Form>}
                </LoadingOverlay>
              {/* </CardBody>
            </Card> */}
        </React.Fragment>
      )

}

export default SingleLocation